import React, { Component } from "react"
import { Howl } from "howler"

const vaderAudio = new Howl({
  src: ["../../sounds/Darth-Vader.mp3"],
  volume: 0.2,
  format: ["mp3"],
})
const r2d2Audio = new Howl({
  src: ["../../sounds/R2-D2.mp3"],
  volume: 0.2,
  format: ["mp3"],
})

let userMode = true

if (typeof window !== "undefined") 
{
  userMode = JSON.parse(localStorage.getItem('userDarkMode'))
  //console.log("usermode in local storage ",userMode)
  if(userMode == null)
  {
    localStorage.setItem('userDarkMode', true)
  }
}
else
{
  console.log("usermode forced to true")
  userMode = true
}


const defaultState = {
  dark: userMode,
  firstTime: true,
  toString: () => `dark`,
  toggleDark: () => {},
  setModeDark: () => {},
  setFirst: () => {},
}

const ThemeContext = React.createContext(defaultState)

class ThemeProvider extends Component {
  state = {
    dark: userMode,
    firstTime:true,
  }

  setFirst = () => (this.state.firstTime = false)

  toString = () => (this.state.dark ? `dark` : `light`)

  toggleDark = () => {
    let dark = !this.state.dark
    this.setState({ dark })
    if (typeof window !== "undefined") 
    {
      localStorage.setItem('userDarkMode', dark)
      userMode = JSON.parse(localStorage.getItem('userDarkMode'))
    }
    else
    {
      userMode = dark
    }
    //console.log("usermode is ",userMode)

    //vaderAudio.stop()
    //r2d2Audio.stop()
    //dark ? vaderAudio.play() : r2d2Audio.play()
  }
  setModeDark = () => {
    let t = true
    this.setState({t})
    if (typeof window !== "undefined") 
    {
      localStorage.setItem('userDarkMode', true)
      userMode = JSON.parse(localStorage.getItem('userDarkMode'))
    }

    console.log("setmodedark is called mode:",userMode)
  }

  render() {
    const { children } = this.props
    const { dark } = this.state
    const { firstTime } = this.state
    return (
      <ThemeContext.Provider
        value={{
          dark,
          firstTime,
          toggleDark: this.toggleDark,
          toString: this.toString,
          setModeDark: this.setModeDark,
          setFirst: this.setFirst
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeContext

export { ThemeProvider }
