import React from "react"
import ThemeContext, { ThemeProvider } from "./src/utils/theme"
import "./src/styles/global.scss"
import 'firebase/app'
import 'firebase/firestore'
import "firebase/auth"
import "firebase/analytics"
import WrapElements from "./src/components/WrapElements"

/** This component wraps around the entire root div where we render our content.
 *  So, this piece of code is applying the theme-light/dark class on the top level
 */
export const wrapRootElement = ({ element }) => {
  return(
    <ThemeProvider>
      <ThemeContext.Consumer>
        {/*({ toString }) => <div className={`theme-${toString()}`}>{element}</div>*/}
        {({ dark }) => {
          if (dark == undefined || dark == null)
          {
            let userMode = true

            if (typeof window !== "undefined") 
            {
              userMode = JSON.parse(localStorage.getItem('userDarkMode'))
              if(userMode == null)
              {
                userMode = true
              }
            }
            else
            {
              userMode = true
            }
            if(userMode)
            {
              return( 
                <div className={`theme-dark`} >{element}</div>
              )
            }
            else
            {
              return( 
                <div className={`theme-light`} >{element}</div>
              )
            }
          }
          return( 
            <div className={`theme-${dark ? `dark` : `light`}`} >{element}</div>
          )
        }}
      </ThemeContext.Consumer>
    </ThemeProvider>
  )
} 