// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-compare-1-js": () => import("./../src/pages/compare_1.js" /* webpackChunkName: "component---src-pages-compare-1-js" */),
  "component---src-pages-compare-js": () => import("./../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-daily-js": () => import("./../src/pages/Daily.js" /* webpackChunkName: "component---src-pages-daily-js" */),
  "component---src-pages-forgot-password-js": () => import("./../src/pages/ForgotPassword.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/Login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-play-js": () => import("./../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-profile-js": () => import("./../src/pages/Profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-reset-password-js": () => import("./../src/pages/ResetPassword.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-signup-js": () => import("./../src/pages/Signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-update-profile-js": () => import("./../src/pages/UpdateProfile.js" /* webpackChunkName: "component---src-pages-update-profile-js" */)
}

