import React, {useState, useEffect } from "react"
import ThemeContext, { ThemeProvider } from "../utils/theme"

 function WrapElements({children}) 
 {

  const [brightMode, setBrightMode] = useState('dark')
  useEffect(() => 
  {
    let userMode = true

    if (typeof window !== "undefined") 
    {
      userMode = JSON.parse(localStorage.getItem('userDarkMode'))
      console.log("usermode in local storage ",userMode)
      if(userMode == null)
      {
        localStorage.setItem('userDarkMode', true)
      }
    }
    else
    {
      console.log("usermode forced to true")
      userMode = true
    }

    if(userMode == true)
    {
      setBrightMode('dark')
    }
    else
    {
      setBrightMode('light')
    }
    
  }, [])
  
  return (
    <ThemeProvider>
      <ThemeContext.Consumer>
        {/*({ toString }) => <div className={`theme-${toString()}`}>{element}</div>*/}
        <div className={`theme-${brightMode}`}>{children}</div>
      </ThemeContext.Consumer>
    </ThemeProvider>
    
  )
}


export default WrapElements